import { useState, useEffect } from 'react'

function usePersistedReducer(storageKey, defaultState) {
  const [state, setState] = useState(() => {
    const persisted = localStorage.getItem(storageKey)
    return persisted === null ? defaultState : JSON.parse(persisted)
  })

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(state))
  }, [storageKey, state])

  return [state, setState]
}

export default usePersistedReducer
