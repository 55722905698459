import React from 'react'

import ExternalLink from 'Components/ExternalLink'
import LatestUpdate from './LatestUpdate'
import ResultsParserView from 'ResultsParserView'
import ToastsManager from 'ToastsManager'

import './RaidParserApp.scss'

const App: React.FC = () => {
  return (
    <ToastsManager>
      <main className="RaidParserApp">
        <header className="RaidParserApp__header">
          <h1>TT2 Raid Data Parser</h1>
          <span className="version">v{process.env.REACT_APP_VERSION}</span>
        </header>
        <article className="RaidParserApp__content">
          <ResultsParserView />
        </article>
        <footer className="RaidParserApp__footer">
          Copyright © 2019 CorvidDallas#1495. Created for the TT2 community; for
          any issues or suggestions, get in touch on Discord,{' '}
          <ExternalLink
            className="link"
            href="https://www.reddit.com/user/Corvid_Dallas"
          >
            Reddit
          </ExternalLink>
          , or{' '}
          <ExternalLink className="link" href="mailto:corvid.dallas@gmail.com">
            via email
          </ExternalLink>
          .
        </footer>
      </main>

      <LatestUpdate version="0.2.3">
        <p>Hey folks,</p>
        <p>
          Time for an update! Have added in a couple of changes, mainly to the
          data tables:
        </p>
        <ul>
          <li>Tables now allow sorting based on certain columns.</li>
          <li>Data for any table can now be copied to the clipboard.</li>
          <li>
            Tables now support pagination to make them easier to use on smaller
            screens.
          </li>
        </ul>
        <p>
          There's other work going on to add new metrics and more flexibility to
          the available reporting, but figured these improvements are useful by
          themselves and worth pushing first.
        </p>
        <p>
          Thanks to everyone who's using the app, and especially those that have
          reached out with feedback and kind words. Stay tuned for more updates,
          and happy tapping!
        </p>
      </LatestUpdate>
    </ToastsManager>
  )
}

export default App
