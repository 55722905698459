import React, { useEffect, useMemo } from 'react'

import Section from 'Components/Section'
import ParserConfigs from './ParserConfigs'
import RaidDataInput from './RaidDataInput'
import PlayerStatsReport from './Reports/PlayerStatsReport'
import NaughtyListReport from './Reports/NaughtyListReport'

import usePersistedReducer from 'hooks/usePersistedReducer'
import usePersistedState from 'hooks/usePersistedState'
import {
  reducer as strategyReducer,
  actions as strategyActions,
} from './reducers/strategy'
import {
  reducer as dataReducer,
  actions as dataActions,
} from './reducers/raidData'

import parseResults from 'services/parseResults'
import buildPlayerStats from './logAggregators/buildPlayerStats'

import './Reports/Reports.scss'

const ResultsParserView: React.FC = () => {
  const [strategies, stratDispatch] = usePersistedReducer(
    'rp:strategies:v0.0.0',
    strategyReducer,
    strategyReducer()
  )
  const [raidData, dataDispatch] = usePersistedReducer(
    'rp:raidData:v0.1.4',
    dataReducer,
    dataReducer()
  )

  // @TEMP: migrate state to use "tt2rp" namespace
  //        remove "rp" namespace in future update
  const [, setMigratedStrategies] = usePersistedState(
    'tt2rp:strategies:v0.0.0',
    strategies
  )
  useEffect(() => {
    setMigratedStrategies(strategies)
  }, [setMigratedStrategies, strategies])
  const [, setMigratedRaidData] = usePersistedState(
    'tt2rp:raidData:v0.1.4',
    raidData
  )
  useEffect(() => {
    setMigratedRaidData(raidData)
  }, [setMigratedRaidData, raidData])

  const handlePartToggle = (titan, part) =>
    stratDispatch(strategyActions.toggle(titan, part))
  const handleDataEdit = (data) => dataDispatch(dataActions.setData(data))
  const handleDataClear = () => dataDispatch(dataActions.clearData())

  const [dataFormat, playerStats] = useMemo(() => {
    const [format, playerSummaries = [], damageLogs = []] = parseResults(
      raidData,
      strategies
    )
    return format !== null
      ? [format, buildPlayerStats(playerSummaries, damageLogs)]
      : [null, []]
  }, [raidData, strategies])

  const tolerance = 1

  return (
    <>
      <Section
        label="Parser Configuration"
        defaultCollapsed={dataFormat !== null}
      >
        <ParserConfigs
          strategies={strategies}
          onPartToggle={handlePartToggle}
        />
      </Section>

      <Section label="Input Raid Data" defaultCollapsed={dataFormat !== null}>
        <RaidDataInput
          value={raidData}
          dataFormat={dataFormat}
          onChange={handleDataEdit}
          onClear={handleDataClear}
        />
      </Section>

      <Section
        label="Player Performance"
        defaultCollapsed={dataFormat === null}
      >
        <PlayerStatsReport data={playerStats || []} tolerance={tolerance} />
      </Section>

      <Section label="Naughty Corner" defaultCollapsed={dataFormat === null}>
        <NaughtyListReport data={playerStats || []} tolerance={tolerance} />
      </Section>
    </>
  )
}

export default ResultsParserView
