import React from 'react'

import Modal from 'Components/Modal'

import './MessageModal.scss'

function MessageModal(props) {
  const { title, onClose, children } = props
  return (
    <Modal title={title} onClose={onClose}>
      <div className="MessageModal">
        {children}
        <p className="MessageModal__sign-off">– Corvid</p>
      </div>
    </Modal>
  )
}

export default MessageModal
