function printCsv(data: Array<Array<any>>): string {
  return data
    .map((r) => {
      return r
        .map((v) => {
          let vString = String(v)
          if (/([,"])/.test(vString)) {
            vString = `"${vString.replace('"', '""')}"`
          }
          return vString
        })
        .join(',')
    })
    .join('\n')
}

export default printCsv
