import React from 'react'

import './Button.scss'

function Button(props) {
  const { label = 'A Button!', onClick = null, className = '' } = props

  return (
    <button className={`Button ${className}`} onClick={onClick}>
      {label}
    </button>
  )
}

export default Button
