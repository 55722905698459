function parseAbbrNumberString(input: string): string | number {
  const numberRx = /^((\d+)|(\d+(\.\d+)?)([KMT]?))$/
  const result: string[] | null = numberRx.exec(input)
  if (result === null) {
    return input
  }

  const [, , integerVal, floatVal, , floatUnit] = result

  if (!!integerVal) {
    return parseInt(integerVal, 10)
  }

  const unitMultiplier =
    floatUnit.toUpperCase() === 'T'
      ? 1000000000
      : floatUnit.toUpperCase() === 'M'
      ? 1000000
      : floatUnit.toUpperCase() === 'K'
      ? 1000
      : 1

  return Math.floor(parseFloat(floatVal) * unitMultiplier)
}

export default parseAbbrNumberString
