import React from 'react'

import Table from 'Components/Table'

import './ParserConfigs.scss'

type StrategyOptions = {
  head: boolean
  torso: boolean
  leftArm: boolean
  rightArm: boolean
  leftHand: boolean
  rightHand: boolean
  leftLeg: boolean
  rightLeg: boolean
}

type Strategy = StrategyOptions & {
  id: string
}

const ParserConfigs = (props) => {
  const { strategies, onPartToggle } = props

  const cellClassName = (isActive) => (isActive ? 'on-strat' : 'off-strat')
  const columns = [
    { id: 'head', label: 'Head', cellClass: cellClassName },
    { id: 'torso', label: 'Torso', cellClass: cellClassName },
    { id: 'leftArm', label: 'Left Arm', cellClass: cellClassName },
    { id: 'rightArm', label: 'Right Arm', cellClass: cellClassName },
    { id: 'leftHand', label: 'Left Hand', cellClass: cellClassName },
    { id: 'rightHand', label: 'Right Hand', cellClass: cellClassName },
    { id: 'leftLeg', label: 'Left Leg', cellClass: cellClassName },
    { id: 'rightLeg', label: 'Right Leg', cellClass: cellClassName },
  ]

  const data = strategies.map((s) => {
    const { titan, ...options } = s
    return {
      ...options,
      id: titan,
    }
  })

  return (
    <div className="ParserConfigs">
      <h3>Titan Lord Attack Patterns</h3>
      <Table
        className="StrategyTable"
        columns={columns}
        data={data}
        mapContent={(status, row, col) => (
          <label>
            <input
              type="checkbox"
              checked={status}
              onChange={() => onPartToggle(row.id, col.id)}
            />{' '}
            {status ? 'Attack' : 'Ignore'}
          </label>
        )}
      />
      <ul>
        <li>
          Left/Right are from the Titan Lord's persective (i.e. the right hand
          is the hand on the left side of the screen).
        </li>
        <li>
          Any armor damage against deselected parts will be counted as
          "off-strat" (i.e. wasted) damage.
        </li>
        <li>
          Players with more than 1% of their total damage as off-strat damage
          will be highlighted.
        </li>
      </ul>
    </div>
  )
}

export default ParserConfigs
