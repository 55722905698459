import React, { useCallback, useEffect, useMemo, useState } from 'react'
import copy from 'clipboard-copy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'

import Button from 'Components/Button'
import { useToasts } from 'ToastsManager'
import printCsv from 'utils/printCsv'
import useRespondAbove from 'hooks/useRespondAbove'

import './DataTable.scss'

type CellValueType = 'number'

type DataTableColumn<T extends string> = {
  id: T
  label?: string
  mapContent?: (cellValue: any, row: DataTableData<T>) => any
  cellClass?: string
  valueType?: CellValueType
  hidden?: boolean
  sortable?: boolean
  sortFunc?: (a: any, b: any) => number
  sortDesc?: boolean
}

type DataTableData<T extends string> = Record<T, any> & { id: string }

type DataTableProps<T extends string> = {
  title: string
  columns: Array<DataTableColumn<T>>
  data: Array<DataTableData<T>>
  ranked?: boolean
  defaultRankColumn?: T
  defaultRankReverse?: boolean
}

const PAGE_SIZE = 10

const CONST_VALUE_TYPE_PROPS: Record<CellValueType, any> = {
  number: {
    baseClass: 'DataTable__cell--number',
    mapContent: (n: number = null) => (n !== null ? n.toLocaleString() : null),
    sortFunc: (a, b) => a - b,
  },
  text: {
    sortFunc: (a = '', b = '') => a.localeCompare(b),
  },
}

function DataTable<T extends string>(props: DataTableProps<T>) {
  const {
    title,
    columns = [],
    data = [],
    ranked = false,
    defaultRankColumn = null,
    defaultRankReverse = false,
  } = props

  const [tableSort, setTableSort] = useState(
    defaultRankColumn !== null
      ? {
          colId: defaultRankColumn,
          reverse: defaultRankReverse,
        }
      : null
  )

  const { successToast, errorToast } = useToasts()

  const { tableColumns, tableData, csvData } = useMemo(() => {
    // Build table columns, based on columns prop
    // 1. If `valueType` is specified and recognised, add the related configs
    //    to the column
    const tableColumns = columns.map((c) => {
      const { valueType = null, ...rest } = c

      return valueType in CONST_VALUE_TYPE_PROPS
        ? {
            ...CONST_VALUE_TYPE_PROPS[valueType],
            ...rest,
          }
        : rest
    })

    // 2. If the table is ranked, add a rank column at the start
    if (ranked) {
      tableColumns.unshift({
        ...CONST_VALUE_TYPE_PROPS.number,
        id: '_rank',
        label: 'Rank',
      })
    }

    // Build table data, based on data prop
    const makeSorter = (sort = null) => {
      if (sort === null) {
        return () => 0
      }

      const { colId } = sort
      const col = tableColumns.find((c) => c.id === colId)

      const { sortFunc = (a, b) => a - b, sortDesc = false } = col

      return (a, b) => {
        return sortFunc(a[colId], b[colId]) * (sortDesc ? -1 : 1)
      }
    }
    const tableData = data.slice().sort(makeSorter(tableSort))

    if (tableSort !== null && true) {
      let lastVal = undefined
      let lastRank = 0
      for (let i = 0; i < tableData.length; i++) {
        const d = tableData[i]
        const sortVal = d[tableSort.colId]
        if (sortVal !== lastVal) {
          lastVal = sortVal
          lastRank = i + 1
          d._rank = lastRank
        } else {
          d._rank = lastRank
        }
      }
    }

    if (tableSort !== null && tableSort.reverse) {
      tableData.reverse()
    }

    const csvData = printCsv(
      [tableColumns.map((c) => c.label || c.id)].concat(
        tableData.map((d) => tableColumns.map((c) => d[c.id]))
      )
    )

    return { tableColumns, tableData, csvData }
  }, [columns, data, ranked, tableSort])

  const handleSortChange = (colId) => {
    setTableSort((prev) => {
      return prev === null || prev.colId !== colId
        ? { colId, reverse: false }
        : { ...prev, reverse: !prev.reverse }
    })
  }

  const handleCopyClick = useCallback(() => {
    copy(csvData).then(
      () => successToast(`"${title}" copied to clipboard`),
      () => errorToast(`Failed to copy "${title}"`)
    )
  }, [title, csvData, successToast, errorToast])

  // Check screen width (for display purposes)
  const isXSWidth = !useRespondAbove('xs')

  return (
    <DTTable
      title={title}
      columns={tableColumns}
      data={tableData}
      narrowMode={isXSWidth}
      sortMode={tableSort}
      onSortChange={handleSortChange}
      onDataCopy={handleCopyClick}
    />
  )
}

export default DataTable

/*------------------------------------*\
    @DTTable Component
\*------------------------------------*/

function DTTable(props) {
  const {
    title = '',
    columns = [],
    data = [],
    narrowMode = false,
    sortMode,
    onSortChange = () => {},
    onDataCopy = () => {},
  } = props

  const [page, setPage] = useState(narrowMode ? 0 : null)

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 0))
  }, [sortMode])

  const handlePaginationToggle = () => {
    setPage((prev) => (prev === null ? 0 : null))
  }

  const pageData =
    page === null ? data : data.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)

  const visibleColumns = columns.filter((c) => !c.hidden)

  return (
    <article className="DataTable">
      <header className="DataTable__header">
        <h1>{title}</h1>
        {narrowMode ? (
          <Button
            label="Copy"
            className="DataTable__copy-control"
            onClick={onDataCopy}
          />
        ) : (
          <Button
            label="Copy to Clipboard"
            className="DataTable__copy-control"
            onClick={onDataCopy}
          />
        )}
      </header>
      <div className="DataTable__scroll-wrapper">
        <table className="DataTable__table">
          <thead>
            <tr>
              {visibleColumns.map((c) => (
                <DTHeaderCell
                  key={c.id}
                  column={c}
                  activeSort={sortMode}
                  onSort={onSortChange}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {pageData.map((r) => (
              <tr key={r.id}>
                {visibleColumns.map((c) => (
                  <DTCell key={c.id} column={c} data={r} />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <footer className="DataTable__footer">
        {page === null ? (
          <span onClick={handlePaginationToggle}>
            Show data in groups of {PAGE_SIZE}
          </span>
        ) : (
          <>
            <span onClick={handlePaginationToggle}>
              Show all data ({data.length} rows)
            </span>
            <DTPagination
              page={page}
              pageCount={Math.ceil(data.length / PAGE_SIZE)}
              onPageChange={setPage}
            />
          </>
        )}
      </footer>
    </article>
  )
}

/*------------------------------------*\
    @DTHeaderCell Component
\*------------------------------------*/

function DTHeaderCell(props) {
  const { column, activeSort, onSort } = props

  const label = column.label !== undefined ? column.label : column.id

  const handleClick = column.sortable ? () => onSort(column.id) : null

  const classNames = [
    'TDHeaderCell',
    column.sortable ? '' : 'TDHeaderCell--no-sort',
    activeSort !== null && activeSort.colId === column.id
      ? activeSort.reverse
        ? 'TDHeaderCell--sorted-reverse'
        : 'TDHeaderCell--sorted'
      : null,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <th className={classNames} onClick={handleClick}>
      {label}
    </th>
  )
}

/*------------------------------------*\
    @DTCell Component
\*------------------------------------*/
type DTCellProps<T extends string> = {
  column: DataTableColumn<T>
  data: DataTableData<T>
}

function DTCell<T extends string>(props: DTCellProps<T>) {
  const { column, data } = props

  let content = data[column.id]

  const className = [
    column.baseClass,
    typeof column.cellClass === 'function'
      ? column.cellClass(content, data)
      : column.cellClass,
  ]
    .filter(Boolean)
    .join(' ')

  if (typeof column.mapContent === 'function') {
    content = column.mapContent(content, data)
  }

  return <td className={className}>{content}</td>
}

/*------------------------------------*\
    @DTPagination
\*------------------------------------*/
function DTPagination(props) {
  const { page, pageCount, onPageChange } = props

  const enablePrev = page > 0
  const enableNext = page < pageCount - 1

  return (
    <span className="DTPagination">
      <DTPaginationButton
        disabled={!enablePrev}
        onClick={() => onPageChange(page - 1)}
        important
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </DTPaginationButton>
      {Array(pageCount)
        .fill(0)
        .map((_, pageNum) => (
          <DTPaginationButton
            key={pageNum}
            current={page === pageNum}
            onClick={() => onPageChange(pageNum)}
          >
            {pageNum + 1}
          </DTPaginationButton>
        ))}
      <DTPaginationButton
        disabled={!enableNext}
        onClick={() => onPageChange(page + 1)}
        important
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </DTPaginationButton>
    </span>
  )
}

function DTPaginationButton(props) {
  const {
    disabled = false,
    current = false,
    important = false,
    onClick = () => {},
    children,
  } = props

  const className = [
    'DTPagination__Button',
    important ? 'DTPagination__Button--important' : null,
    disabled ? 'DTPagination__Button--disabled' : null,
    current ? 'DTPagination__Button--current' : null,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <button
      className={className}
      disabled={disabled}
      onClick={disabled ? null : onClick}
    >
      {children}
    </button>
  )
}
