/*
  Heavily inspired by this blog post:
  https://www.jayfreestone.com/writing/react-portals-with-hooks/
*/

import { useEffect, useRef } from 'react'

function usePortalContainer(rootId) {
  // Create a new div element (and a ref to hold a reference to it), to use as
  // the container for the portal.
  const portalContainer = useRef(null)
  if (portalContainer.current === null) {
    const container = document.createElement('div')
    portalContainer.current = container
  }

  useEffect(() => {
    // To attach the portal container to the dom, we'll look for an element with
    // id rootId; if it doesn't already exist, we'll dynamically add one, so we
    // won't need to pre-emptively add any additional root elements to the html
    // page.
    //
    // If we dynamically add the root element, we'll also tag the element, so we
    // can clean it up once it's no longer in use.
    let portalRoot = document.getElementById(rootId)
    if (portalRoot === null) {
      portalRoot = document.createElement('div')
      portalRoot.setAttribute('id', rootId)
      portalRoot.dataset.isTransientRoot = 'true'
      document.body.insertBefore(
        portalRoot,
        document.body.lastElementChild.nextSibling
      )
    }

    // Attach the container element to the root
    portalRoot.appendChild(portalContainer.current)

    // On clean-up, remove the attached element from the root. If the root was
    // dynamically added and is now empty, we can also clean up that up.
    return () => {
      portalRoot.removeChild(portalContainer.current)
      if (
        portalRoot.dataset.isTransientRoot === 'true' &&
        portalRoot.childNodes.length === 0
      ) {
        portalRoot.remove()
      }
    }
  }, [rootId])

  return portalContainer.current
}

export default usePortalContainer
