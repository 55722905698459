const toggle = (titan, part) => ({
  type: 'strategy/toggle',
  payload: { titan, part },
})

const actions = {
  toggle,
}

const initialState = [
  {
    titan: 'Takedar the Reborn',
    head: true,
    torso: true,
    leftArm: true,
    rightArm: true,
    leftHand: true,
    rightHand: true,
    leftLeg: true,
    rightLeg: true,
  },
  {
    titan: 'Jukk the Overseer',
    head: true,
    torso: true,
    leftArm: true,
    rightArm: true,
    leftHand: true,
    rightHand: true,
    leftLeg: true,
    rightLeg: true,
  },
  {
    titan: 'Lojak the Fissure',
    head: true,
    torso: true,
    leftArm: true,
    rightArm: true,
    leftHand: true,
    rightHand: true,
    leftLeg: true,
    rightLeg: true,
  },
  {
    titan: 'Terro the Seeker',
    head: true,
    torso: true,
    leftArm: true,
    rightArm: true,
    leftHand: true,
    rightHand: true,
    leftLeg: true,
    rightLeg: true,
  },
  {
    titan: 'Sterl the Unmaker',
    head: true,
    torso: true,
    leftArm: true,
    rightArm: true,
    leftHand: true,
    rightHand: true,
    leftLeg: true,
    rightLeg: true,
  },
  {
    titan: 'Mohaca the Gale',
    head: true,
    torso: true,
    leftArm: true,
    rightArm: true,
    leftHand: true,
    rightHand: true,
    leftLeg: true,
    rightLeg: true,
  },
]

const reducer = (state = initialState, action = {}) => {
  const { type } = action

  switch (type) {
    case toggle().type: {
      const { titan, part } = action.payload
      return state.map((s) => {
        if (s.titan !== titan) {
          return s
        }

        return {
          ...s,
          [part]: !s[part],
        }
      })
    }
    default:
      return state
  }
}

export { reducer, actions }
