import parser_3_4_1 from './parsers/v3-4-1'
import parser_3_4 from './parsers/v3-4'

function parseResults(
  logCsv: string,
  strategies: TitanStrategy[] = []
): [string, PlayerSummary[], DamageLog[]] {
  // List available parsers, in order of precedence
  const parsers = [parser_3_4_1, parser_3_4]

  for (var i = 0; i < parsers.length; i++) {
    const parseResults = parsers[i](logCsv, strategies)
    if (parseResults !== null) {
      return parseResults
    }
  }

  return [null, null, null]
}

export default parseResults
