import { useEffect, useState } from 'react'

const BREAKPOINTS = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
}

function useRespondAbove(breakpoint) {
  const [match, setMatch] = useState(false)

  useEffect(() => {
    if (!(breakpoint in BREAKPOINTS)) {
      console.warn(`useRespondAbove: unrecognised breakpoint "${breakpoint}"`)
      setMatch(false)
      return
    }

    const minWidth = BREAKPOINTS[breakpoint]
    const bpQuery = `(min-width: ${minWidth}px)`
    const mediaQueryList = window.matchMedia(bpQuery)

    const handleChange = (mql) => setMatch(mql.matches)

    handleChange(mediaQueryList)

    mediaQueryList.addListener(handleChange)

    return () => mediaQueryList.removeListener(handleChange)
  }, [breakpoint])

  return match
}

export default useRespondAbove
