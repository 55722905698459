import React, { useMemo } from 'react'

import DataTable from 'Components/DataTable'

function NaughtyListView(props) {
  const { data, tolerance = 0 } = props

  const { tableColumns, tableData } = useMemo(() => {
    const tableColumns = [
      { id: 'playerName', label: 'Player', sortable: true },
      {
        id: 'totalDamage',
        label: 'Total Damage',
        valueType: 'number',
        sortable: true,
        sortDesc: true,
      },
      {
        id: 'offStratDmg',
        label: 'Off-Strat Damage',
        valueType: 'number',
        cellClass: 'danger',
        sortable: true,
      },
      {
        id: 'offStratPercent',
        label: 'Off-Strat Damage %',
        valueType: 'number',
        cellClass: 'danger',
        sortable: true,
        mapContent: (v) => (typeof v === 'number' ? `${v.toFixed(2)}%` : null),
      },
      {
        id: 'titanNumber',
        label: 'Titan #',
        valueType: 'number',
        sortable: true,
      },
      { id: 'titanName', label: 'Titan', valueType: 'text', sortable: true },
      { id: 'part', label: 'Body Part', valueType: 'text', sortable: true },
      {
        id: 'damage',
        label: 'Damage Dealt',
        valueType: 'number',
        sortable: true,
      },
    ]

    const tableData = data
      .map((d) => ({
        ...d,
        offStratPercent: (100 * d.offStratDmg) / d.totalDamage,
      }))
      .filter((d) => d.offStratPercent > tolerance)
      .sort((a, b) => b.offStratPercent - a.offStratPercent)
      .map((d) => {
        const { playerLogs, ...playerDeets } = d
        return playerLogs
          .filter((l) => l.dmgCategory === 'offStrat')
          .map((l) => ({
            ...playerDeets,
            titanNumber: l.titanNumber,
            titanName: l.titanName,
            part: l.part,
            damage: l.damage,
          }))
      })
      .flat()

    return { tableColumns, tableData }
  }, [data, tolerance])

  if (data.length === 0) {
    return <div className="ResultsView__no-data">No data to display.</div>
  }
  if (tableData.length === 0) {
    return (
      <div className="ResultsView__no-data">
        No one did enough off-strat damage to show up here for this raid. Well
        played!
      </div>
    )
  }

  return (
    <>
      <ul className="ResultsView__report-notes">
        <li>
          For players with over 1% off-strat damage, shows a breakdown of which
          parts were being incorrectly hit and how much damage was done to each.
        </li>
        <li>
          Remember that Left/Right are from the Titan Lord's persective (i.e.
          the right hand is the hand on the left side of the screen).
        </li>
      </ul>
      <DataTable
        title="Off-Strat Attackers"
        columns={tableColumns}
        data={tableData}
        defaultRankColumn="offStratPercent"
        defaultRankReverse
      />
    </>
  )
}

export default NaughtyListView
