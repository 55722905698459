import { useReducer, useEffect } from 'react'

function usePersistedReducer(storageKey, reducer, defaultState, init = null) {
  const [state, dispatch] = useReducer(
    reducer,
    defaultState,
    (defaultState) => {
      const persisted = localStorage.getItem(storageKey)
      return persisted !== null
        ? JSON.parse(persisted)
        : init !== null
        ? init(defaultState)
        : defaultState
    }
  )

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(state))
  }, [storageKey, state])

  return [state, dispatch]
}

export default usePersistedReducer
