import React from 'react'

import './ExternalLink.scss'

function ExternalLink(props) {
  const { href, className = '', children } = props

  return (
    <a
      className={`ExternalLink ${className}`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export default ExternalLink
