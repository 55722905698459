import React from 'react'

import Button from 'Components/Button'

import './RadioGroup.scss'

function RadioGroup(props) {
  const {
    options = [
      { id: '1', label: 'Option 1ne' },
      { id: '2', label: 'Option 2wo' },
      { id: '3', label: 'Option 3hree' },
    ],
    selected = '2',
    onChange = null,
  } = props

  return (
    <div className="RadioGroup">
      {options.map((opt) => {
        const className = [
          'RadioGroup__option',
          opt.id === selected
            ? 'RadioGroup__option--active'
            : 'RadioGroup__option--idle',
        ]
          .filter(Boolean)
          .join(' ')
        return (
          <Button
            key={opt.id}
            label={opt.label || opt.id}
            className={className}
            onClick={() => onChange(opt.id)}
          />
        )
      })}
    </div>
  )
}

export default RadioGroup
