import * as Papa from 'papaparse'

import parseAbbrNumberString from '../parseAbbrNumberString'

type Titan =
  | 'Takedar the Reborn'
  | 'Jukk the Overseer'
  | 'Lojak the Fissure'
  | 'Terro the Seeker'
  | 'Sterl the Unmaker'
  | 'Mohaca the Gale'
type TitanLayer = 'Armor' | 'Body' | 'Skeleton'
type TitanPart =
  | 'Head'
  | 'Torso'
  | 'Left Arm'
  | 'Right Arm'
  | 'Left Hand'
  | 'Right Hand'
  | 'Left Leg'
  | 'Right Leg'
type DamageCategory = 'onStrat' | 'offStrat' | 'skeleton'

type PlayerSummary = {
  playerName: string
  playerCode: string
  totalAttacks: number
  totalDamage: number
}

type DamageLog = {
  playerCode: string
  titanNumber: number
  titanName: Titan
  layer: TitanLayer
  part: TitanPart
  damage: number
  dmgCategory: DamageCategory
}

type TitanStrategy = {
  titan: Titan
  head: boolean
  torso: boolean
  leftArm: boolean
  rightArm: boolean
  leftHand: boolean
  rightHand: boolean
  leftLeg: boolean
  rightLeg: boolean
}

const EXPECTED_FIELDS = [
  'Player Name',
  'Player Code',
  'Titan Number',
  'Titan Name',
  'Total Attacks',
  'Total Damage',
  'Armor Head',
  'Armor Torso',
  'Armor Left Arm',
  'Armor Right Arm',
  'Armor Left Hand',
  'Armor Right Hand',
  'Armor Left Leg',
  'Armor Right Leg',
  'Body Head',
  'Body Torso',
  'Body Left Arm',
  'Body Right Arm',
  'Body Left Hand',
  'Body Right Hand',
  'Body Left Leg',
  'Body Right Leg',
  'Skeleton Head',
  'Skeleton Torso',
  'Skeleton Left Arm',
  'Skeleton Right Arm',
  'Skeleton Left Hand',
  'Skeleton Right Hand',
  'Skeleton Left Leg',
  'Skeleton Right Left',
]
const NON_DMG_COLUMNS = 6

function parseResults(
  logCsv: string,
  strategies: TitanStrategy[] = []
): [string, PlayerSummary[], DamageLog[]] {
  // The results data uses lines of commas as dividers
  // Clear them out to reduce noise
  const cleanedLogCsv = logCsv.replace(/^,+$/gm, '')

  const { data, errors } = Papa.parse(cleanedLogCsv, { skipEmptyLines: true })
  const [logHeader, ...logData] = data

  if (errors.length > 0) {
    console.error('parse error', errors)
    return null
  } else if (!validateFormat(logHeader)) {
    return null
  }

  const players: Map<string, PlayerSummary> = new Map()
  const damageLogs: DamageLog[] = []
  const dmgHeader = logHeader.slice(NON_DMG_COLUMNS)

  logData.forEach((d, i) => {
    const [
      playerName,
      playerCode,
      titanNumber,
      titanName,
      totalAttacks,
      totalDamage,
    ] = d.slice(0, NON_DMG_COLUMNS)

    if (!players.has(playerCode)) {
      const player: PlayerSummary = {
        playerName,
        playerCode,
        totalAttacks: parseInt(totalAttacks, 10),
        totalDamage: parseAbbrNumberString(totalDamage),
      }
      players.set(player.playerCode, player)
    }

    // If the titan number (and name) are "No Attack", then the log doesn't
    // contain a part breakdown, and we can't do anything else here.
    if (titanNumber === 'No Attack') {
      return
    }

    const strategy = strategies.find((s) => s.titan === titanName)

    const dmgFields = d.slice(NON_DMG_COLUMNS)
    dmgFields.forEach((value, i) => {
      const [layer, part] = getDmgAttributes(dmgHeader[i])
      const damage = parseAbbrNumberString(value)

      if (damage === 0) return

      const dmgCategory =
        layer === 'Skeleton'
          ? 'skeleton'
          : layer === 'Armor' &&
            strategy !== undefined &&
            strategy[camelCaseify(part)] === false
          ? 'offStrat'
          : 'onStrat'

      const attack: DamageLog = {
        playerCode,
        titanNumber: parseInt(titanNumber, 10) + 1,
        titanName,
        layer,
        part,
        damage,
        dmgCategory,
      }
      damageLogs.push(attack)
    })
  })

  const retValue: [string, PlayerSummary[], DamageLog[]] = [
    'v3.4',
    Array.from(players.values()),
    damageLogs,
  ]
  return retValue
}

export default parseResults

/*------------------------------------*\
    @Utils
\*------------------------------------*/

function validateFormat(headerRow: string[]): boolean {
  return (
    headerRow.length === EXPECTED_FIELDS.length &&
    EXPECTED_FIELDS.every((field, i) => headerRow[i] === field)
  )
}

function getDmgAttributes(label): [TitanLayer, TitanPart] {
  if (label === 'Skeleton Right Left') {
    label = 'Skeleton Right Leg'
  }

  const [layer, ...part] = label.split(' ')
  return [layer, part.join(' ')]
}

function camelCaseify(input: string): string {
  return input.toLowerCase().replace(/\s+(.)/g, (m, p1) => p1.toUpperCase())
}
