import React from 'react'

import './Table.scss'

type RowData = Record<string, any>

type ColumnDefinition = {
  id: string
  label?: string
  mapContent?: (value: any, row: RowData, col: ColumnDefinition) => any
  headerClass?: string
  cellClass?: string | ((value: any, row: RowData) => string)
}

type TableProps = {
  columns: ColumnDefinition[]
  data: RowData[]
  mapContent?: (value: any, row: RowData, col: ColumnDefinition) => any
  mapColHeader?: (value: any, col: ColumnDefinition) => any
}

const Table = (props: TableProps) => {
  const {
    columns,
    data,
    mapContent,
    mapColHeader,
    rowLabels = true,
    className = '',
  } = props

  return (
    <div className="Table__scroll-wrapper">
      <table className={`Table ${className}`}>
        <thead>
          <tr>
            {rowLabels && <th />}
            {columns.map((col) => (
              <th key={col.id} className={col.headerClass}>
                {mapColHeader
                  ? mapColHeader(col.label || col.id, col)
                  : col.label || col.id}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {rowLabels && <th>{row.label || row.id}</th>}
              {columns.map((col) => (
                <TableCell
                  key={col.id}
                  value={row[col.id]}
                  row={row}
                  col={col}
                  mapContent={col.mapContent || mapContent}
                  className={col.cellClass}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table

function TableCell(props) {
  const { value, row, col, mapContent = (v) => v, className = null } = props

  const cellClassName =
    typeof className === 'function' ? className(value, row, col) : className

  return <td className={cellClassName}>{mapContent(value, row, col)}</td>
}
