import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import { FocusOn } from 'react-focus-on'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import usePortalContainer from 'hooks/usePortalContainer'

import './Modal.scss'

type ModalVariant = 'default' | 'danger' | 'bare'
type ModalProps = {
  title: string
  variant?: ModalVariant
  onClose: () => void
  children: any
}

function Modal(props: ModalProps) {
  const { title, variant = 'default', onClose, children } = props

  const portalContainer = usePortalContainer('modal-root')
  const modalRef = useRef(null)

  const handleBackdropClick = (e) => {
    if (modalRef.current !== null && !modalRef.current.contains(e.target)) {
      onClose()
    }
  }

  const className = [
    'Modal',
    variant !== 'default' ? `Modal--${variant}-variant` : null,
  ]
    .filter(Boolean)
    .join(' ')

  const modalContent = (
    <section className={className} ref={modalRef}>
      <header onClick={onClose}>
        <h2>{title}</h2>
        <FontAwesomeIcon icon={faTimes} className="icon" />
      </header>
      {children}
    </section>
  )

  const modal = (
    <FocusOn onClickOutside={onClose}>
      <aside className="Modal__backdrop" onClick={handleBackdropClick}>
        {modalContent}
      </aside>
    </FocusOn>
  )

  return ReactDOM.createPortal(modal, portalContainer)
}

export default Modal
