import React, { useEffect, useState } from 'react'

import MessageModal from './MessageModal'

import usePersistedState from 'hooks/usePersistedState'

function LatestUpdate(props) {
  const { version, children } = props

  const [prevVersion, setPrevVersion] = usePersistedState(
    'tt2rp:prevVersion',
    null
  )
  const [open, setOpen] = useState(
    prevVersion !== null && prevVersion !== version
  )
  useEffect(() => setPrevVersion(version), [version, setPrevVersion])

  if (!open) return null

  return (
    <MessageModal
      title={`Latest Update (v${version})`}
      onClose={() => setOpen(false)}
    >
      {children}
    </MessageModal>
  )
}

export default LatestUpdate
