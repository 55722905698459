const setData = (data) => ({
  type: 'raidData/setData',
  payload: data,
})

const clearData = () => ({
  type: 'raidData/clearData',
})

const actions = { setData, clearData }

const reducer = (state = '', action = {}) => {
  const { type } = action

  switch (type) {
    case setData().type:
      return action.payload
    case clearData().type:
      return ''
    default:
      return state
  }
}

export { reducer, actions }
