function buildPlayerStats(summaries, damageLogs) {
  const stats = summaries
    .map((p) => {
      const dmgTotals = {
        onStrat: 0,
        offStrat: 0,
        skeleton: 0,
      }

      const playerLogs = damageLogs.filter((l) => l.playerCode === p.playerCode)

      playerLogs.forEach((l) => {
        dmgTotals[l.dmgCategory] += l.damage
      })

      // Due to rounding, the total damage from the summary could be less that
      // the sum of the parts as calculated here. Set it to the larger of the two
      const totalDamage = Math.max(
        p.totalDamage,
        dmgTotals.onStrat + dmgTotals.offStrat + dmgTotals.skeleton
      )
      // Calculate on strat dmg as total dmg minus the non-on strat categories,
      // to allow for cases where not all on strat damage is capture in the
      // breakdown (e.g. incomplete damage logs)
      const onStratDmg = totalDamage - dmgTotals.offStrat - dmgTotals.skeleton

      return {
        ...p,
        totalDamage,
        onStratDmg,
        offStratDmg: dmgTotals.offStrat,
        skeletonDmg: dmgTotals.skeleton,
        onStratAvg: Math.round(onStratDmg / p.totalAttacks),
        playerLogs,
      }
    })
    .sort((a, b) => b.onStratDmg - a.onStratDmg)
    .map((s, i) => ({ ...s, playerRank: i + 1 }))

  return stats
}

export default buildPlayerStats
