import React, { useState } from 'react'

import RadioGroup from 'Components/RadioGroup'
import DataTable from 'Components/DataTable'

function PlayerStatsReport(props) {
  const { data, tolerance = 0 } = props

  const [reportType, setReportType] = useState('new')

  if (data.length === 0) {
    return <div className="ResultsView__no-data">No data to display.</div>
  }

  const highlightHighOffStratDmg = (offStratDmg, data) => {
    const ratio = (100 * offStratDmg) / data.totalDamage
    return ratio > tolerance ? 'danger' : ''
  }

  const columns =
    reportType === 'old'
      ? [
          {
            id: 'playerName',
            label: 'Player',
            valueType: 'text',
            sortable: true,
          },
          { id: 'playerCode', label: 'ID', hidden: true },
          {
            id: 'totalAttacks',
            label: 'Attacks',
            valueType: 'number',
            sortable: true,
            sortDesc: true,
          },
          {
            id: 'onStratDmg',
            label: 'On-Strat Damage',
            valueType: 'number',
            sortable: true,
            sortDesc: true,
          },
        ]
      : [
          {
            id: 'playerName',
            label: 'Player',
            valueType: 'text',
            sortable: true,
          },
          { id: 'playerCode', label: 'ID', hidden: true },
          {
            id: 'onStratDmg',
            label: 'On-Strat Damage',
            valueType: 'number',
            sortable: true,
            sortDesc: true,
          },
          {
            id: 'offStratDmg',
            label: 'Off-Strat Damage',
            cellClass: highlightHighOffStratDmg,
            valueType: 'number',
            sortable: true,
          },
          {
            id: 'skeletonDmg',
            label: 'Skeleton Damage',
            valueType: 'number',
            sortable: true,
          },
          {
            id: 'totalDamage',
            label: 'Total Damage',
            valueType: 'number',
            sortable: true,
            sortDesc: true,
          },
          {
            id: 'totalAttacks',
            label: 'Attacks',
            valueType: 'number',
            sortable: true,
            sortDesc: true,
          },
          {
            id: 'onStratAvg',
            label: 'Avg. On-Strat Damage',
            valueType: 'number',
            sortable: true,
            sortDesc: true,
          },
        ]

  return (
    <>
      <div className="ResultsView__controls">
        <RadioGroup
          options={[
            { id: 'new', label: 'Detailed Player Stats Report' },
            { id: 'old', label: 'Legacy Format Player Stats' },
          ]}
          selected={reportType}
          onChange={setReportType}
          className="ResultsView__type-control"
        />
      </div>
      {reportType === 'new' ? (
        <ul className="ResultsView__report-notes">
          <li>
            "On-strat damage" is the sum of all body damage and armor damage to
            allowed parts.
          </li>
          <li>
            "Off-strat damage" is the sum of all armor damage to disallowed
            parts. Values are highlighted where they're greater than 1% of the
            player's total damage.
          </li>
          <li>
            Rankings are based on total on-strat damage (not total damage), and
            so may differ from the rankings shown in-game.
          </li>
        </ul>
      ) : reportType === 'old' ? (
        <ul className="ResultsView__report-notes">
          <li>
            This report follows the same format as the pre 3.4 raid export csv.
          </li>
          <li>
            The damage total (and resultant rank) in this report excludes any
            off-strat/skeleton damage, so may differ from the in game display.
          </li>
        </ul>
      ) : null}
      <DataTable
        key={reportType}
        title={reportType === 'old' ? 'Legacy Player Stats' : 'Player Stats'}
        columns={columns}
        data={data}
        defaultRankColumn="onStratDmg"
        ranked
      />
    </>
  )
}

export default PlayerStatsReport
