import React from 'react'

import Button from 'Components/Button'

import './RaidDataInput.scss'

function RaidDataInput(props) {
  const { value, dataFormat = null, onChange, onClear } = props

  const handleChange = (e) => {
    onChange(e.target.value)
  }
  return (
    <div className="RaidDataInput">
      <textarea
        className="RaidDataInput__input"
        rows={10}
        value={value}
        onChange={handleChange}
        placeholder="Paste exported clan raid stats here..."
      />
      {value === '' ? null : dataFormat === null ? (
        <span className="RaidDataInput__status RaidDataInput__status--error">
          Parsing failed: unrecognised data format
        </span>
      ) : (
        <span className="RaidDataInput__status RaidDataInput__status--success">
          Parsed successfully ({dataFormat} data)
        </span>
      )}
      {value && (
        <Button
          className="RaidDataInput__clear-control"
          label="Clear data"
          onClick={onClear}
        />
      )}
    </div>
  )
}

export default RaidDataInput
