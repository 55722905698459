import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import './Section.scss'

function Section(props) {
  const {
    label = '',
    defaultCollapsed = false,
    variant = null,
    children,
  } = props

  const [collapsed, setCollapsed] = useState(defaultCollapsed)

  const handleToggle = () => setCollapsed((prev) => !prev)

  const classNames = ['Section', variant === null ? '' : `Section--${variant}`]
    .filter(Boolean)
    .join(' ')

  return (
    <section className={classNames}>
      <header onClick={handleToggle}>
        <h2>{label}</h2>
        <FontAwesomeIcon icon={collapsed ? faPlus : faMinus} className="icon" />
      </header>
      {!collapsed && children}
    </section>
  )
}

export default Section
