import React, { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import './ToastsManager.scss'

let nextId = 1

const ToastsContext = React.createContext({})

const TOAST_DURATION = 3000

function ToastsManager(props) {
  const { children } = props

  const [toasts, setToasts] = useState([])

  const clearToast = (id) => {
    setToasts((prev) => prev.filter((t) => t.id !== id))
  }

  const toastsApi = useMemo(() => {
    function toast(message, level = 'info') {
      const id = nextId++
      setToasts((prev) => prev.concat({ id, message, level }))
      setTimeout(clearToast, TOAST_DURATION, id)
      return id
    }

    return {
      toast,
      successToast: (message) => toast(message, 'success'),
      errorToast: (message) => toast(message, 'error'),
    }
  }, [])

  return (
    <div className="ToastsManager">
      <ToastsContext.Provider value={toastsApi}>
        {children}
      </ToastsContext.Provider>
      <div className="ToastsManager__ToastContainer">
        {toasts
          .map((toast) => (
            <Toast
              key={toast.id}
              {...toast}
              onClose={() => clearToast(toast.id)}
            />
          ))
          .reverse()}
      </div>
    </div>
  )
}

export default ToastsManager

function useToasts() {
  return React.useContext(ToastsContext)
}

export { ToastsContext, useToasts }

function Toast(props) {
  const { level, message, onClose } = props

  const className = `Toast Toast--${level}`

  return (
    <div className={className} onClick={onClose}>
      <span>{message}</span>
      <FontAwesomeIcon className="icon" icon={faTimes} />
    </div>
  )
}
